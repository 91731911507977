<template>
  <div class="eSealFunctional">
    <h2>功能特点</h2>
    <div class="characteristicExhibition">
      <div v-for="(item,index) in list" :key="index" :class="{active:active_index===index}" @click="cgActiveIndex(index)">
        <div class="featuresBox">
          <img :src="require(`@/assets/image/productCenter/${item.icon}`)" alt="功能特点">
          <dl>
            <dt>{{item.title}}</dt>
            <dd v-if="active_index===index">{{item.intro}}</dd>
          </dl>
        </div>
        <div class="previewEffect" v-if="active_index===index">
          <img :src="require(`@/assets/image/productCenter/${list[active_index].image}`)" alt="预览效果">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['list'],
  data () {
    return {
      active_index: 0,
      interval: -1
    }
  },
  mounted () {
    this.cgActiveIndex(0)
  },
  methods: {
    cgActiveIndex (index) {
      clearInterval(this.interval)
      this.active_index = index
      this.interval = setInterval(() => {
        if (this.active_index < this.list.length - 1) {
          this.active_index++
        } else {
          this.active_index = 0
        }
      }, 5000)
    }
  }
}
</script>

<style lang="scss" scoped>
.eSealFunctional {
  padding: 0.9rem 0.32rem;
  box-sizing: border-box;
  h2 {
    font-size: 0.48rem;
    color: #303b50;
    line-height: 0.67rem;
    margin-bottom: 0.64rem;
    text-align: center;
  }
}
.featuresBox {
  width: 6.86rem;
  height: 1.22rem;
  background: #f0f4f7;
  border-radius: 0.02rem;
  display: flex;
  padding: 0.32rem;
  box-sizing: border-box;
  margin-bottom: 0.24rem;
  img {
    width: 0.6rem;
    height: 0.58rem;
  }
  dl {
    margin-left: 0.24rem;
  }
  dt {
    font-size: 0.28rem;
    font-weight: bold;
    color: #2d3b52;
    line-height: 0.58rem;
  }
  dd {
    margin-top: 0.24rem;
    font-size: 0.26rem;
    color: #2d3b52;
    line-height: 0.33rem;
  }
}
.previewEffect {
  margin: 0.16rem 0 0.41rem;
}
.active {
  .featuresBox {
    height: max-content;
    background: #e9f1fe;
    margin-bottom: 0;
  }
  dt {
    line-height: 0.4rem;
  }
}
</style>