<template>
  <div>
    <div class="verifySignBox">
      <img src="@/assets/image/productCenter/verifySignBanner.png" alt="签名验签">
      <h2>签名验签</h2>
      <p>使用签名验签服务不但可以有效地保障业务数据的真实性和有效性，同时还能大大降低应用系统中实现电子签名的复杂度，因此签名验签被广泛应用在电子政务、电子商务、电子金融、医疗卫生等各个行业中。</p>
    </div>
    <v-functional :list="functional" />
    <v-featuresItem :list="features" />
    <v-learnMore />
    <v-networkSecurity />
  </div>
</template>

<script>
import learnMore from './components/learnMore.vue';
import featuresItem from './components/featuresItem.vue';
import functionalItem from './components/functionalItem2.vue';
import networkSecurity from '../../../components/networkSecurity.vue';
export default {
  data () {
    return {
      functional: [
        {
          icon: 'introduce1.png',
          title: '签名验证',
          intro: '提供PKCS#1、国密算法签名等多种格式的签名验证功能',
          image: 'functional2-img6.png'
        },
        {
          icon: 'introduce4.png',
          title: '数字证书验证',
          intro: '提供数字证书验证功能，支持对签名、加密证书进行全面验证。',
          image: 'functional2-img7.png'
        },
        {
          icon: 'verifySign1.png',
          title: '身份认证',
          intro: '基于数字证书的身份认证系统，支持不同 CA 系统的证书验证。',
          image: 'functional2-img8.png'
        }
      ],
      features: [
        {
          title: '合法性',
          intro:
            '采用权威第三方认证机构颁发的数字证书，签名验签结果具有法律效力。'
        },
        {
          title: '高可用',
          intro: '支持集群部署，充分保证了系统的稳定运行。'
        },
        {
          title: '兼容性',
          intro:
            '支持各种主流操作系统；面向应用系统提供多语言开发API，方便开发者调用；支持多种格式的数字签名及验证；支持不同认证机构颁发的数字证书。'
        },
        {
          title: '易用性',
          intro:
            '服务端应用接口集成简单、部署便捷，B/S模式管理界面方便管理员轻松配置管理。'
        }
      ]
    }
  },
  components: {
    'v-learnMore': learnMore,
    'v-featuresItem': featuresItem,
    'v-functional': functionalItem,
    'v-networkSecurity': networkSecurity,
  },
}
</script>

<style lang="scss" scoped>
.verifySignBox {
  width: 7.5rem;
  height: 9.8rem;
  background: #f0f4ff;
  padding: 0.98rem 0.53rem 0;
  box-sizing: border-box;
  text-align: center;
  img {
    width: 6rem;
  }
  h2 {
    font-size: 0.48rem;
    color: #303b50;
    line-height: 0.67rem;
    margin: 0.78rem 0 0.26rem;
  }
  p {
    font-size: 0.28rem;
    color: #303b50;
    line-height: 0.44rem;
  }
}
</style>